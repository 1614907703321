import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { usersOnboardingService } from "../../../../../Services/usersOnboarding";

const GradientDonutDevopsServerSetupChart = () => {
  const [pendingServerSetup, setPendingServerSetup] = useState(0);
  const [createdServerSetup, setCreatedServerSetup] = useState(0);
  const [serverSetupGraph, setServerSetupGraph] = useState([pendingServerSetup, createdServerSetup]);

  const getTotalServerSetupGraphData = async () => {
    try {
      const data = await usersOnboardingService.getTotalServerSetupGraphData();
      if (data) {
        setPendingServerSetup(data.pendingServerSetup);
        setCreatedServerSetup(data.createdServerSetup);
        setServerSetupGraph([
          data.pendingServerSetup,
          data.createdServerSetup,
        ]);
      }
    } catch (error) {
      console.error("Error fetching graph data:", error);
    }
  };

  useEffect(() => {
    getTotalServerSetupGraphData();
  }, [])

  const options = {
    chart: {
      type: "donut",
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    legend: {
      formatter: function (val, opts) {
        return val + " - " + opts.w.globals.series[opts.seriesIndex];
      },
      position: "bottom",
    },
    title: {
      text: "Server Setup",
    },
    labels: ["Pending", "Created"],
    colors: ["#808080", "#50c150"],
  };

  return (
    <div id="chart" 
    // className="chart_design_port"
    className="p-2 py-3"
    >
      <ReactApexChart
        options={options}
        series={serverSetupGraph}
        type="donut"
        width={360}
      />
    </div>
  );
};

export default GradientDonutDevopsServerSetupChart;
