import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { dateStatus, timeSince } from "../../../../utils/universalFunction";
import { usersOnboardingService } from "../../../../Services/usersOnboarding";
import { GradientDonutDevopsServerSetupChart, GradientDonutAdminClientChart } from "./Chart";

const DevopsDashboard = () => {

  const [greeting, setGreeting] = useState("");
  const userName = JSON.parse(localStorage.getItem("firstName"));
  const [totalClient, setTotalClient] = useState("");
  const [totalNewClient, setTotalNewClient] = useState("");
  const [totalServerSetup, setTotalServerSetup] = useState("");
  const [totalNewServerSetup, setTotalNewServerSetup] = useState("");
  const [totalPendingServerSetup, setTotalPendingServerSetup] = useState("");
  const [totalNewPendingServerSetup, setTotalNewPendingServerSetup] = useState("");

  const [chartData, setChartData] = useState([
    { id: "gradient-donut-chart", component: <GradientDonutAdminClientChart /> },
    { id: "gradient-donut-chart", component: <GradientDonutDevopsServerSetupChart userType={"client"} /> },
  ]);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(chartData);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setChartData(items);
  };

  const [recentActivities, setRecentActivities] = useState([]);

  const getUserActivities = async () => {
    let data = await usersOnboardingService.getUserActivities()
    setRecentActivities(data?.data)
  }

  const getGreeting = () => {
    const currentTime = new Date().getHours();
    let greeting;

    if (currentTime >= 5 && currentTime < 12) {
      greeting = "Good Morning";
    } else if (currentTime >= 12 && currentTime < 18) {
      greeting = "Good Afternoon";
    } else {
      greeting = "Good Evening";
    }
    setGreeting(greeting);
  };

  const getTotalUserClientDataForDevops = async () => {
    let data = await usersOnboardingService.getTotalUserClientDataForDevops();
    setTotalClient(data?.clientData)
    setTotalNewClient(data?.totalClientWithinLastMonth)
    setTotalServerSetup(data?.onBoardUserData)
    setTotalNewServerSetup(data?.totalServerSetupWithinLastMonth)
    setTotalPendingServerSetup(data?.pendingServerSetUp)
    setTotalNewPendingServerSetup(data?.totalPendingServerSetupWithinLastMonth)
  };

  useEffect(() => {
    getUserActivities();
    getGreeting();
    getTotalUserClientDataForDevops();
  }, [])

  return (
    <>
      <div className="">
        <Container fluid>
          <Row className="chart__overview">
            <Col md={12} className="border-bottom mb-3">
              <div className="text-center py-2">
                <h3 className="m-0">
                  {greeting}, {userName}
                </h3>
                <p className="m-0">Welcome to your dashboard.</p>
              </div>
            </Col>
          </Row>
          <Row className="chart__overview height-home">
            <Col md={4} sm={12}>
              <Card className="mb-3">
                <Card.Body>
                  <div className="d-flex gap-3">
                    <div className="study_icopn">
                      <i className="fa-solid fa-building-user"></i>
                    </div>
                    <div>
                      <Card.Title>Total Clients</Card.Title>
                      <Card.Text className="m-0">{totalClient || 0}</Card.Text>
                      <Card.Text>{totalNewClient || 0} new client added in past 1 month.</Card.Text>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} sm={12}>
              <Card className="mb-3">
                <Card.Body>
                  <div className="d-flex gap-3">
                    <div className="study_icopn">
                      <i className="fa-solid fa-building-user"></i>
                    </div>
                    <div>
                      <Card.Title>Total Server setup</Card.Title>
                      <Card.Text className="m-0">{totalServerSetup || 0}</Card.Text>
                      <Card.Text>{totalNewServerSetup || 0} new Server setup in past 1 month.</Card.Text>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} sm={12}>
              <Card className="mb-3">
                <Card.Body>
                  <div className="d-flex gap-3">
                    <div className="study_icopn">
                      <i className="fa-solid fa-building-user"></i>
                    </div>
                    <div>
                      <Card.Title>Total Pending Server Setup</Card.Title>
                      <Card.Text className="m-0">{totalPendingServerSetup || 0}</Card.Text>
                      <Card.Text>
                        {totalNewPendingServerSetup || 0} new pending server setup in past 1 month.
                      </Card.Text>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md={8}>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="chartList" direction="horizontal">
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      <Container fluid className="p-0">
                        <Row className="drag__swap__widget">
                          {chartData.map((chart, index) => (
                            <Col md={6} sm={12}>
                              <div
                                className="color_div_Current mb-3 rounded"
                                style={{ background: "#e3e6ed", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
                              >
                                <div className="color_rent mb-0">
                                  <h6 className="home_text font-heading m-0">
                                    {chart.component}
                                  </h6>
                                </div>
                              </div>
                            </Col>
                          ))}
                          {provided.placeholder}
                        </Row>
                      </Container>
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Col>
            <Col md={4}>
              <Col md={12} sm={12}>
                <Card className="mb-3">
                  <Card.Body>
                    <Card.Title>Recent activity</Card.Title>
                    <Card.Text className="m-0">
                      <div style={{ height: 270, overflow: "auto" }}>
                        {recentActivities?.map(({ date, data }, index) => (
                          <div key={index}>
                            <p
                              className="p-2 m-0"
                              style={{
                                position: "sticky",
                                top: "0",
                                background: "#e3e6ed",
                              }}
                            >
                              {dateStatus(date)}
                            </p>
                            {data?.map((item, key) => (
                              <div key={key} className="activity__section">
                                <div>
                                  <ul className="p-0 m-0">
                                    <li>
                                      <p className="m-0">
                                        {item.moduleType === "message"
                                          ? item.activity
                                          : item.dataName +
                                          " Successfully " +
                                          (item.activity === "Add"
                                            ? "Added"
                                            : item.activity === "Update"
                                              ? "Updated"
                                              : item.activity === "Delete"
                                                ? "Deleted"
                                                : item.activity.replace(
                                                  "_",
                                                  " "
                                                ) + " Updated")}
                                      </p>
                                    </li>
                                    <p
                                      className="m-0 p-0"
                                      style={{ color: "gray" }}
                                    >
                                      {timeSince(item?.createdAt)}
                                    </p>
                                  </ul>
                                </div>
                              </div>
                            ))}
                          </div>
                        ))}
                      </div>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
export default DevopsDashboard;
